



















import { Component, Prop, Vue } from "vue-property-decorator";
import { MenuItem } from "@/components/Menu/menu-item";

@Component({})
export default class MenuOption extends Vue {
  @Prop() public item!: MenuItem;
  @Prop() public rootOption!: boolean;

  public get OptionActive(): boolean {
    if (this.$route.fullPath === this.item.link) {
      return true;
    }
    let rutas: string[] = this.item.link.split("/");
    let ruta_padre: string = "";
    for (let i = 0; i < rutas.length; i++) {
      if (i < rutas.length - 1) {
        ruta_padre += rutas[i] + (i + 1 < rutas.length - 1 ? "/" : "");
      }
    }
    if (this.$route.matched.length >= 2) {
      const element = this.$route.matched[1];
      if (element.path === ruta_padre) {
        return true;
      }
    }
    return false;
  }

  public OptionClick() {
    this.$router.push(this.item.link);
  }
}
